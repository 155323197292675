import "styles/pages/payment-methods.scss"

import React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"
import PageHeader from "components/PageHeader/PageHeader"
import PageSubHeader from "components/PageSubHeader/PageSubHeader"

import PaymentMethodsIco1 from "assets/images/payment-methods-ico-1.svg"
import PaymentMethodsIco2 from "assets/images/payment-methods-ico-2.svg"
import PaymentMethodsIco3 from "assets/images/payment-methods-ico-3.svg"
import PaymentMethodsIco4 from "assets/images/payment-methods-ico-4.svg"

const PaymentMethods = () => {
  const title = "Formy płatności"

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumbs title={title} />

      <section className="payment-methods">
        <div className="container-fluid">
          <PageHeader title={title} />

          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="payment-methods__item">
                <div className="payment-methods__item-image">
                  <img src={PaymentMethodsIco1} alt="" />
                </div>
                <PageSubHeader title="Przelew na konto bankowe" />
                <p>
                  Forma płatności dla Klientów preferujących samodzielne
                  logowanie do banku internetowego, wizytę w placówce banku lub
                  na poczcie. Po zarezerwowaniu zamówionego towaru, klient
                  otrzymuje mailem numer rachunku bankowego, na który należy
                  przelać właściwą kwotę. Z chwilą zaksięgowania wpłaty na
                  rachunku bankowym zamówienie jest przekazywane do magazynu w
                  celu przygotowania wysyłki.
                </p>
                <br />
                <address>
                  Numer konta bankowego:
                  <br />
                  <strong>19 1140 2004 0000 3302 7774 2399</strong>
                  <br />
                  <br />
                  MEMTECH Sp. z o.o. Sp. k.
                  <br />
                  Doroszewskiego 7<br />
                  01-318 Warszawa
                  <br />
                  NIP: 726-265-53-49
                </address>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="payment-methods__item">
                <div className="payment-methods__item-image">
                  <img src={PaymentMethodsIco2} alt="" />
                </div>
                <PageSubHeader title="Za pobraniem" />
                <p>Zapłata gotówką kurierowi przy odbiorze zamówienia.</p>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="payment-methods__item">
                <div className="payment-methods__item-image">
                  <img src={PaymentMethodsIco3} alt="" />
                </div>
                <PageSubHeader title="Przelew online" />
                <p>
                  Forma płatności elektronicznej obsługiwana przez Przelewy24.pl
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="payment-methods__item">
                <div className="payment-methods__item-image">
                  <img src={PaymentMethodsIco4} alt="" />
                </div>
                <PageSubHeader title="Płatność przy odbiorze w sklepie" />
                <p>Płatność w sklepie przy odbiorze towaru.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PaymentMethods
